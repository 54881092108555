<template>
  <a-card>
    <div slot="title">技能包阶段信息</div>

    <basis-form ref="basis" :mainFieldId="mainFieldId" :fieldId="fieldId" />

    <a-row>
      <a-col v-bind="$formItemLayoutWithoutLabel.wrapperCol">
        <a-button :loading="loading" type="primary" shape="round" style="width:160px" @click="onSubmit">
          <span>保存</span>
        </a-button>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
import events from '@/components/MultiTab/events'
import * as skillApi from '@/api/skill'
import BasisForm from './components/BasisForm'

export default {
  name: 'SkillManagementSkillPhaseCreate',
  components: {
    BasisForm
  },
  data() {
    return {
      loading: false,
      mainFieldId: NaN,
      fieldId: NaN
    }
  },
  // watch: {
  //   $route() {
  //     const {
  //       $route: { name },
  //       initData
  //     } = this

  //     if (name === 'SkillManagementSkillPhaseCreate') {
  //       initData()
  //     }
  //   }
  // },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      const {
        $route: {
          params: { skillId }
        }
      } = this
      skillApi.detail(skillId).then(res => {
        const {
          field: {
            id: fieldId,
            parent: { id: mainFieldId }
          }
        } = res.data
        this.mainFieldId = mainFieldId || NaN
        this.fieldId = fieldId || NaN
      })
    },
    onSubmit() {
      const {
        $route: {
          params: { skillId }
        },
        $refs: { basis },
        $notification,
        toSkill
      } = this

      basis.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          try {
            const { formatForm } = basis
            const form = formatForm()
            form.skillId = skillId

            skillApi
              .createPhase(form)
              .then(res => {
                $notification['success']({ message: '操作成功' })
                toSkill()
              })
              .finally(() => (this.loading = false))
          } catch (error) {
            console.log(error)
            $notification['error']({ message: '提示', description: '脚本错误' })
            this.loading = false
          }
        }
      })
    },
    close() {
      events.$emit('close')
      this.$router.go(-1)
    },
    toSkill() {
      const {
        $router,
        $route: {
          params: { skillId }
        }
      } = this

      events.$emit('close')
      $router.replace(`/skill-management/skill/${skillId}?t=PHASE`)
    }
  }
}
</script>

<style lang="less" scoped></style>
